import {Component} from 'react';
import { empty } from '../helpers/generalHelper';
import { message } from 'antd';

export default class ErrorHandler extends Component{
    static handleGeneralError = errorResponse => {
        console.log(errorResponse);

        if(!empty(errorResponse)){

            if(errorResponse.status !== 400){
                message.error(`Error ${errorResponse.status}: ${errorResponse.statusText}`);
            }

            if(!empty(errorResponse.data)){
                if(typeof errorResponse.data === 'string'){
                    message.error(errorResponse.data);
                }

                if(!empty(errorResponse.data.error)){
                    if(!empty(errorResponse.data.error.message)){
                        message.error(errorResponse.data.error.message);
                    }
                }
            }
        }
    };
}