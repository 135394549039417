import { Component } from "react";
import { connect } from "react-redux";
import { posOperations } from "../../state/ducks/pos";
import { empty } from "../helpers/generalHelper";
import { apiCall } from "../helpers/networkHelper";
import * as endpoint from "../utils/endpoints";
import { MOMENT_SQL_DATETIME_FORMAT, TRX_TYPE_NORMAL } from "../utils/constants";
import { message } from "antd";
import ErrorHandler from "./ErrorHandler";
import PropTypes from "prop-types";
import moment from "moment";

class Synchronizer extends Component {
    static propTypes = {
        autoStart: PropTypes.bool
    };

    static defaultProps = {
        autoStart: false
    };

    constructor(props) {
        super(props);

        this.syncTimer = null;
        this.syncInterval = 300000;
    }

    componentDidMount() {
        if(this.props.autoStart){
            console.log("Periodic data sync is activated.");
            console.log("Current sync interval is every " + (this.syncInterval / 1000) + "s.");
            this.syncTimer = setInterval(() => {
                Synchronizer.syncPosTrxs(this.props.posState, this.props.setTrxProps)
                    .catch(err => ErrorHandler.handleGeneralError(err));
            }, this.syncInterval);
        }
    }

    componentWillUnmount() {
        if(this.props.autoStart) {
            clearInterval(this.syncTimer);
        }
    }

    static syncPosTrxs = async (posState, setTrxProps, silent = true, successCallback=()=>{}, errorCallback=()=>{}) => {
        const trxs = posState.posTrx.filter(trx =>
            !trx.is_synced &&
            trx.trx_type === TRX_TYPE_NORMAL &&
            trx.trx_status_id === 3
        );
        const unsyncedCount = trxs.length;
        let currentSyncIndex = 1;
        let isError = false;

        if(navigator.onLine){
            if (empty(trxs)) {
                if (silent) {
                    console.log("All transactions has been synced");
                } else {
                    message.success("All transactions has been synced");
                }
            } else {
                let hideLoadingMessage = () => {};

                const onSyncSuccess = (currentTrx) => {
                    if (silent) {
                        console.log(`Successfully synced transaction #${currentTrx.local_trx_id}`);
                    } else {
                        hideLoadingMessage();
                        message.success(`Successfully synced transaction #${currentTrx.local_trx_id}`);
                    }
                };

                const onSyncFailed = (currentTrx) => {
                    if (silent) {
                        console.log(`Error syncing transaction #${currentTrx.local_trx_id}`);
                    } else {
                        hideLoadingMessage();
                        message.error(`Error syncing transaction #${currentTrx.local_trx_id}`);
                    }
                    isError = true;
                };

                while (currentSyncIndex <= unsyncedCount) {
                    const currentTrx = trxs[currentSyncIndex - 1];

                    if (silent) {
                        console.log(`Syncing ${currentSyncIndex} of ${unsyncedCount} transaction(s)..`);
                    } else {
                        hideLoadingMessage = message.loading(`Syncing ${currentSyncIndex} of ${unsyncedCount} transaction(s)..`, 0);
                    }

                    await Synchronizer.syncPosTrx(currentTrx,
                        setTrxProps,
                        () => {
                            onSyncSuccess(currentTrx);
                        },
                        () => {
                            onSyncFailed(currentTrx);
                        });

                    currentSyncIndex++;
                }
            }
        }else{
            if (silent) {
                console.error(`Tidak ada koneksi internet! Silakan cek dan coba kembali`);
            } else {
                message.error(`Tidak ada koneksi internet! Silakan cek dan coba kembali`);
            }
            isError = true;
        }

        if(!isError){
            successCallback();
        }else{
            errorCallback();
            throw new Error('Sync gagal');
        }

        return {isError};
    };

    static syncPosTrx = async (trx, setTrxProps, successCallback = () => {}, errorCallback = () => {}) => {
        const formData = {
            is_offline: 1,
            local_sync_time: moment().format(MOMENT_SQL_DATETIME_FORMAT),
            trxs: [trx]
        };

        return await new Promise((resolve, reject) => {
            return apiCall(
                endpoint.POST_POS_TRX,
                "post",
                formData,
                response => {
                    resolve(response);
                },
                response => {
                    reject(response);
                }
            );
        })
            .then(result => {
                // API call success
                if (!empty(result.data)) {
                    const res = result.data[0];
                    if (res.status === "success") {
                        setTrxProps(trx.local_trx_id, {
                            deposit_balance: res.deposit_balance,
                            pos_sales_trx_head_id: res.pos_sales_trx_head_id,
                            trx_id: res.trx_id,
                            created_at: res.created_at,
                            sync_token: res.sync_token,
                            sync_time: res.sync_time,
                            is_synced: true
                        });
                        successCallback();
                    }else{
                        errorCallback();
                    }
                }else{
                    errorCallback();
                }
            })
            .catch(error => {
                console.log("Sync failed, error: " + JSON.stringify(error));
                ErrorHandler.handleGeneralError(error);
                errorCallback();
            });
    };

    render() {
        return "";
    }
}

const mapStateToProps = state => {
    return {
        posState: state.posState
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Synchronizer);