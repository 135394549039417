import React, {Component} from 'react';
import {Route, Router, Switch, withRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {Spin} from "antd";
import {connect} from "react-redux";
import PosReceiptPublic from "./pages/PosReceiptPublic";

const browserHistory = createBrowserHistory();

class Routes extends Component {
    render() {
        const { globalLoading, globalLoadingMessage } = this.props.states;

        return (
            <Spin spinning={globalLoading} tip={globalLoadingMessage}>
                <Router history={browserHistory}>
                    <Switch>
                        <Route
                            path="/app/receipt/:receipt_token"
                            component={withRouter(PosReceiptPublic)}
                        />

                        <Route
                            path="/app/"
                            component={() => <div>Invalid URL</div>}
                        />
                    </Switch>
                </Router>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        states: state.authState.states
    };
};

export default connect(mapStateToProps)(Routes);