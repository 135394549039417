import actions from './actions';

const storeItems = items => actions.storeItems(items);
const storeItemCategories = itemCategories =>
    actions.storeItemCategories(itemCategories);
const storeDiscounts = discounts => actions.storeDiscounts(discounts);
const storeCommissionWorkers = commission_workers =>
    actions.storeCommissionWorkers(commission_workers);
const addTrxGlobalDisc = (currentLocalTrxId, discountItem) =>
    actions.addTrxGlobalDisc(currentLocalTrxId, discountItem);
const addTrxDetail = (
    currentLocalTrxId,
    currentTrxDetail,
    itemMeta,
    itemData
) =>
    actions.addTrxDetail(
        currentLocalTrxId,
        currentTrxDetail,
        itemMeta,
        itemData
    );
const editTrxDetail = (local_trx_id, local_trx_detail_id, detail) =>
    actions.editTrxDetail(local_trx_id, local_trx_detail_id, detail);
const deleteTrxDetail = (local_trx_id, local_trx_detail_id, deletedDetail=null, actionType = "DELETE_ITEM") =>
    actions.deleteTrxDetail(local_trx_id, local_trx_detail_id, deletedDetail, actionType);
const logModifyTrxDetail = (local_trx_id, local_trx_detail_id, deletedDetail=null, actionType = "MODIFY_ITEM") =>
    actions.logModifyTrxDetail(local_trx_id, local_trx_detail_id, deletedDetail, actionType);
const markTrxDetailsAsPrinted = (local_trx_id) =>
    actions.markTrxDetailsAsPrinted(local_trx_id);
const setTrxCustomer = (localTrxId, customerData) =>
    actions.setTrxCustomer(localTrxId, customerData);
const setTrxProps = (local_trx_id, newProps) =>
    actions.setTrxProps(local_trx_id, newProps);
const populateAppointments = (appointments = []) => actions.populateAppointments(appointments);
const setNewAppointment = (appointmentData = {}) => actions.setNewAppointment(appointmentData);
const setAppointmentProps = (local_appointment_id, newProps) =>
    actions.setAppointmentProps(local_appointment_id, newProps);
const deleteAppointment = local_appointment_id => actions.deleteAppointment(local_appointment_id);

const setNewLocalTrx = (additionalProps = []) => actions.setNewLocalTrx(additionalProps);
const addTrx = trx => actions.addTrx(trx);
const switchCurrentTrx = local_trx_id => actions.switchCurrentTrx(local_trx_id);
const resetTrx = local_trx_id => actions.resetTrx(local_trx_id);
const deleteTrx = (local_trx_id, trx = null) => actions.deleteTrx(local_trx_id, trx);
const overwriteTrxs = trxs => actions.overwriteTrxs(trxs);


const setTrxStates = (local_trx_id, newStates) =>
    actions.setTrxStates(local_trx_id, newStates);
const setPosConfig = posConfig => actions.setPosConfig(posConfig);
const setPosVariables = posVariables => actions.setPosVariables(posVariables);

export default {
    storeItems,
    storeItemCategories,
    storeDiscounts,
    storeCommissionWorkers,
    addTrxGlobalDisc,
    populateAppointments,
    setNewAppointment,
    setAppointmentProps,
    deleteAppointment,
    setNewLocalTrx,
    addTrx,
    addTrxDetail,
    editTrxDetail,
    deleteTrxDetail,
    logModifyTrxDetail,
    markTrxDetailsAsPrinted,
    setTrxCustomer,
    setTrxProps,
    switchCurrentTrx,
    resetTrx,
    deleteTrx,
    overwriteTrxs,
    setTrxStates,
    setPosConfig,
    setPosVariables
};
