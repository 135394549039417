import { number_format } from "../helpers/stringHelper";
import { empty } from "../helpers/generalHelper";
import { TRX_TYPE_COUPON_SALES, TRX_TYPE_NORMAL } from "../utils/constants";

export default class PosPriceCalculator {
    static calculateDiscByItem = (
        item_price,
        deduction_type,
        deduction_value,
        currencyCode,
        discObj
    ) => {
        let totalDisc = 0;
        if (deduction_type === 'percentage') {
            totalDisc = item_price * (deduction_value / 100);

            if(!empty(discObj.max_deduction_value)){
                if(totalDisc > discObj.max_deduction_value){
                    totalDisc = discObj.max_deduction_value;
                }
            }
        } else {
            totalDisc = deduction_value;
        }

        if(totalDisc > item_price){
            totalDisc = item_price;
        }

        const formattedDisc = '-' + number_format(totalDisc, 0) + ` ${currencyCode}`;
        const priceAfterDisc = item_price - totalDisc;

        return {
            totalDisc,
            formattedDisc,
            priceAfterDisc
        };
    };

    static calculateCustomDisc = (itemPrice, customDisc, itemDisc, currencyCode) => {
        let item_price = itemPrice;
        let totalDisc = 0;

        if (!empty(itemDisc)) {
            itemDisc.map(disc => {
                const calculatedDiscByItem = PosPriceCalculator.calculateDiscByItem(
                    item_price,
                    disc.deduction_type,
                    disc.deduction_value,
                    currencyCode,
                    disc
                );
                item_price = calculatedDiscByItem.priceAfterDisc;
                return disc;
            });
        }

        if (customDisc.custom_discount_type === 'percentage') {
            totalDisc = item_price * (customDisc.custom_discount_value / 100);
        } else {
            totalDisc = customDisc.custom_discount_value;
        }

        if(totalDisc > item_price){
            totalDisc = item_price;
        }

        const formattedDisc = '-' + number_format(totalDisc, 0) + ` ${currencyCode}`;
        const priceAfterDisc = item_price - totalDisc;

        return {
            totalDisc,
            formattedDisc,
            priceAfterDisc
        };
    };

    static calculateCouponItem = (detail, trx, currencyCode) => {
        let totalDisc = 0;
        const coupon = trx.coupon;

        const pos_item_variant = detail.itemMeta.pos_item_variants.find(
            variant =>
                variant.pos_item_variant_id === detail.pos_item_variant_id
        );
        let modifierPrice = 0;
        if(!empty(detail.pos_item_pos_item_modifiers)){
            detail.pos_item_pos_item_modifiers.map(modifier=>{
                modifier.pos_item_modifier_options.map(option=>{
                    modifierPrice += parseInt(option.option_price);
                    return option;
                });
                return modifier;
            })
        }
        let item_price = parseInt(pos_item_variant.variant_price) + modifierPrice;

        if (!empty(coupon)) {
            if (
                coupon.coupon_item.pos_link_type === 'item' &&
                !empty(detail.coupon) &&
                coupon.coupon_item.pos_item_id ===
                detail.coupon.pos_item_id
            ) {
                if (coupon.coupon_item.item_deduction_type === 'percentage') {
                    totalDisc =
                        item_price *
                        (coupon.coupon_item.item_deduction_value / 100);
                } else {
                    totalDisc = coupon.coupon_item.item_deduction_value;
                }
            }

            if (
                coupon.coupon_item.pos_link_type === 'item_category' &&
                coupon.coupon_item.pos_item_category_id ===
                detail.itemMeta.pos_item_category_id
            ) {
                totalDisc =
                    item_price *
                    (coupon.coupon_item.item_category_deduction_percentage /
                        100);
            }
        }

        if(totalDisc > item_price){
            totalDisc = item_price;
        }

        const formattedDisc = '-' + number_format(totalDisc, 0) + ` ${currencyCode}`;
        const priceAfterDisc = item_price - totalDisc;

        return {
            totalDisc,
            formattedDisc,
            priceAfterDisc
        };
    };

    static calculateRedeemItem = (detail, trx, currencyCode) => {
        let totalDisc = 0;
        const redeem = trx.redeem;

        const pos_item_variant = detail.itemMeta.pos_item_variants.find(
            variant =>
                variant.pos_item_variant_id === detail.pos_item_variant_id
        );
        let modifierPrice = 0;
        if(!empty(detail.pos_item_pos_item_modifiers)){
            detail.pos_item_pos_item_modifiers.map(modifier=>{
                modifier.pos_item_modifier_options.map(option=>{
                    modifierPrice += parseInt(option.option_price);
                    return option;
                });
                return modifier;
            })
        }
        let item_price = parseInt(pos_item_variant.variant_price) + modifierPrice;

        if (!empty(redeem)) {
            if (
                redeem.redeem_item.pos_link_type === 'item' &&
                !empty(detail.redeem) &&
                redeem.redeem_item.pos_item_id ===
                detail.redeem.pos_item_id
            ) {
                if (redeem.redeem_item.item_deduction_type === 'percentage') {
                    totalDisc =
                        item_price *
                        (redeem.redeem_item.item_deduction_value / 100);
                } else {
                    totalDisc = redeem.redeem_item.item_deduction_value;
                }
            }

            if (
                redeem.redeem_item.pos_link_type === 'item_category' &&
                redeem.redeem_item.pos_item_category_id ===
                detail.itemMeta.pos_item_category_id
            ) {
                totalDisc =
                    item_price *
                    (redeem.redeem_item.item_category_deduction_percentage /
                        100);
            }
        }

        if(totalDisc > item_price){
            totalDisc = item_price;
        }

        const formattedDisc = '-' + number_format(totalDisc, 0) + ` ${currencyCode}`;
        const priceAfterDisc = item_price - totalDisc;

        return {
            totalDisc,
            formattedDisc,
            priceAfterDisc
        };
    };

    static calculateItemPrice = (trx, detail, currencyCode) => {
        const qty = detail.qty;
        const itemDisc = detail.pos_item_discounts;
        const customDisc = {
            custom_discount_type: detail.custom_discount_type,
            custom_discount_value: detail.custom_discount_value
        };
        const pos_item_variant = detail.itemMeta.pos_item_variants.find(
            variant =>
                variant.pos_item_variant_id === detail.pos_item_variant_id
        );
        let modifierPrice = 0;
        if(!empty(detail.pos_item_pos_item_modifiers)){
            detail.pos_item_pos_item_modifiers.map(modifier=>{
                modifier.pos_item_modifier_options.map(option=>{
                    modifierPrice += parseInt(option.option_price);
                    return option;
                });
                return modifier;
            })
        }
        let item_price = parseInt(pos_item_variant.variant_price) + modifierPrice;
        let discountApplied = false;
        let totalDiscountValue = 0;

        if (!empty(trx.coupon) && !discountApplied) {
            if (
                trx.coupon.coupon_item.pos_link_type === 'item' &&
                !empty(detail.coupon) &&
                    trx.coupon.coupon_item.pos_item_id ===
                    detail.coupon.pos_item_id
            ) {
                const calculatedItemPrice = PosPriceCalculator.calculateCouponItem(detail, trx, currencyCode);
                item_price = calculatedItemPrice.priceAfterDisc;
                totalDiscountValue = calculatedItemPrice.totalDisc;
                discountApplied = true;
            }

            if (
                trx.coupon.coupon_item.pos_link_type === 'item_category' &&
                trx.coupon.coupon_item.pos_item_category_id ===
                detail.itemMeta.pos_item_category_id
            ) {
                const calculatedItemPrice = PosPriceCalculator.calculateCouponItem(detail, trx, currencyCode);
                item_price = calculatedItemPrice.priceAfterDisc;
                totalDiscountValue = calculatedItemPrice.totalDisc;
                discountApplied = true;
            }
        }

        if (!empty(trx.redeem) && !discountApplied) {
            if (
                trx.redeem.redeem_item.pos_link_type === 'item' &&
                !empty(detail.redeem) &&
                trx.redeem.redeem_item.pos_item_id ===
                detail.redeem.pos_item_id
            ) {
                const calculatedItemPrice = PosPriceCalculator.calculateRedeemItem(detail, trx, currencyCode);
                item_price = calculatedItemPrice.priceAfterDisc;
                totalDiscountValue = calculatedItemPrice.totalDisc;
                discountApplied = true;
            }

            if (
                trx.redeem.redeem_item.pos_link_type === 'item_category' &&
                trx.redeem.redeem_item.pos_item_category_id ===
                detail.itemMeta.pos_item_category_id
            ) {
                const calculatedItemPrice = PosPriceCalculator.calculateRedeemItem(detail, trx, currencyCode);
                item_price = calculatedItemPrice.priceAfterDisc;
                totalDiscountValue = calculatedItemPrice.totalDisc;
                discountApplied = true;
            }
        }

        if (!empty(customDisc.custom_discount_value) && !discountApplied) {
            const calculatedCustomDisc = PosPriceCalculator.calculateCustomDisc(
                item_price,
                customDisc,
                itemDisc,
                currencyCode
            );
            item_price = calculatedCustomDisc.priceAfterDisc;
            totalDiscountValue = calculatedCustomDisc.totalDisc;
            discountApplied = true;
        }

        if (!empty(itemDisc) && !discountApplied) {
            itemDisc.map(disc => {
                const calculatedDiscByItem = PosPriceCalculator.calculateDiscByItem(
                    item_price,
                    disc.deduction_type,
                    disc.deduction_value,
                    currencyCode,
                    disc
                );
                item_price = calculatedDiscByItem.priceAfterDisc;
                totalDiscountValue = calculatedDiscByItem.totalDisc;
                return disc;
            });
            discountApplied = true;
        }

        return {
            totalPriceValue: item_price * qty,
            totalDiscountValue: totalDiscountValue * qty
        };
    };

    static calculateGrossItemPrice = (trx, detail) => {
        const qty = detail.qty;
        const pos_item_variant = detail.itemMeta.pos_item_variants.find(
            variant =>
                variant.pos_item_variant_id === detail.pos_item_variant_id
        );
        let modifierPrice = 0;
        if(!empty(detail.pos_item_pos_item_modifiers)){
            detail.pos_item_pos_item_modifiers.map(modifier=>{
                modifier.pos_item_modifier_options.map(option=>{
                    modifierPrice += parseInt(option.option_price);
                    return option;
                });
                return modifier;
            })
        }
        let item_price = parseInt(pos_item_variant.variant_price) + modifierPrice;

        return {
            totalPriceValue: item_price * qty
        };
    };

    static calculateGrossTotal = (trxDetails, itemNature = '', currencyCode) => {
        let grossTotal = 0;
        let details = empty(trxDetails) ? [] : trxDetails;

        if(itemNature === 'SERVICE'){
            details = details.filter(row => {
                return row.itemMeta.item_nature === 'SERVICE';
            });
        }
        if(itemNature === 'RETAIL'){
            details = details.filter(row => {
                return row.itemMeta.item_nature === 'RETAIL';
            });
        }

        details.map(detail => {
            const pos_item_variant = detail.itemMeta.pos_item_variants.find(
                variant =>
                    variant.pos_item_variant_id === detail.pos_item_variant_id
            );
            let modifierPrice = 0;
            if(!empty(detail.pos_item_pos_item_modifiers)){
                detail.pos_item_pos_item_modifiers.map(modifier=>{
                    modifier.pos_item_modifier_options.map(option=>{
                        modifierPrice += parseInt(option.option_price);
                        return option;
                    });
                    return modifier;
                })
            }
            grossTotal += (parseInt(pos_item_variant.variant_price) + modifierPrice) * detail.qty;
            return detail;
        });

        return {
            grossTotal,
            formattedSubTotal: number_format(grossTotal, 0) + ` ${currencyCode}`
        };
    };

    static calculateSubTotal = (trx, currencyCode) => {
        const trxDetails = trx.details;
        let subTotal = 0;

        trxDetails.map(detail => {
            subTotal += PosPriceCalculator.calculateItemPrice(trx, detail, currencyCode).totalPriceValue;
            return detail;
        });

        return {
            subTotal,
            formattedSubTotal: number_format(subTotal, 0) + ` ${currencyCode}`
        };
    };

    static calculateGlobalDiscount = (trx, itemPrice = '', currencyCode) => {
        let subTotal = 0;
        if (itemPrice === '') {
            subTotal = PosPriceCalculator.calculateSubTotal(trx, currencyCode).subTotal;
        } else {
            subTotal = itemPrice;
        }

        const globalDiscounts = trx.discounts;
        let totalDiscountValue = 0;
        let discountApplied = false;

        if (trx.trx_type === TRX_TYPE_NORMAL &&
            !empty(trx.coupon) &&
            !discountApplied) {
            const {
                pos_link_type,
                discount_percentage,
                max_discount_value
            } = trx.coupon.coupon_item;
            if (pos_link_type === 'discount') {
                totalDiscountValue = subTotal * (discount_percentage / 100);
                if (max_discount_value > 0) {
                    if (totalDiscountValue > max_discount_value) {
                        totalDiscountValue = max_discount_value;
                    }
                }

                discountApplied = true;
            }
        }

        if (trx.trx_type === TRX_TYPE_NORMAL &&
            !empty(trx.redeem) &&
            !discountApplied) {
            const {
                pos_link_type,
                discount_percentage,
                max_discount_value
            } = trx.redeem.redeem_item;
            if (pos_link_type === 'discount') {
                totalDiscountValue = subTotal * (discount_percentage / 100);
                if (max_discount_value > 0) {
                    if (totalDiscountValue > max_discount_value) {
                        totalDiscountValue = max_discount_value;
                    }
                }

                discountApplied = true;
            }
        }

        if (
            trx.trx_type === TRX_TYPE_NORMAL &&
            !empty(trx.customer) &&
            !empty(trx.customer.discount_level) &&
            'reward_value' in trx.customer.discount_level &&
            !discountApplied
        ) {
            if (trx.customer.discount_level.reward_value > 0) {
                totalDiscountValue =
                    subTotal * (trx.customer.discount_level.reward_value / 100);

                discountApplied = true;
            }
        }

        if (!empty(globalDiscounts) && !discountApplied) {
            globalDiscounts.map(discount => {
                if (
                    discount.discount_type === 'basic' ||
                    discount.discount_type === 'custom'
                ) {
                    if (discount.deduction_type === 'percentage') {
                        let discountValue =
                            subTotal * (discount.deduction_value / 100);
                        if(!empty(discount.max_deduction_value)){
                            if(discountValue > discount.max_deduction_value){
                                discountValue = discount.max_deduction_value;
                            }
                        }

                        totalDiscountValue += discountValue;
                    } else {
                        totalDiscountValue += discount.deduction_value;
                        if(totalDiscountValue > subTotal){
                            totalDiscountValue = subTotal;
                        }
                    }

                    discountApplied = true;
                }

                return discount;
            });
        }

        return {
            totalDiscountValue,
            formattedDiscountValue:
                '-' + number_format(totalDiscountValue, 0) + ` ${currencyCode}`
        };
    };

    static calculateServiceCharge = (trx, serviceCharges, currencyCode) => {
        let totalServiceCharge = 0;
        const trxDetails = trx.details;

        if (!empty(trxDetails) && trx.trx_type === TRX_TYPE_NORMAL) {
            trxDetails.map(detail => {
                let itemPrice = PosPriceCalculator.calculateItemPrice(
                    trx,
                    detail,
                    currencyCode
                ).totalPriceValue;
                itemPrice =
                    itemPrice -
                    PosPriceCalculator.calculateGlobalDiscount(trx, itemPrice, currencyCode)
                        .totalDiscountValue;

                if (!empty(detail.pos_sales_type)) {
                    if (
                        !empty(
                            detail.pos_sales_type.pos_sales_type_service_charges
                        )
                    ) {
                        detail.pos_sales_type.pos_sales_type_service_charges.map(
                            serviceCharge => {
                                const appliedServiceCharge = serviceCharges.find(
                                    row =>
                                        row.pos_serviceCharge_id ===
                                        serviceCharge.pos_serviceCharge_id
                                );
                                if (!empty(appliedServiceCharge)) {
                                    totalServiceCharge +=
                                        itemPrice *
                                        (parseFloat(
                                            appliedServiceCharge.charge_percent
                                        ) /
                                            100);
                                }

                                return serviceCharge;
                            }
                        );
                    }
                }

                return detail;
            });
        }

        return {
            serviceChargeValue: totalServiceCharge,
            formattedServiceCharge:
                number_format(totalServiceCharge, 0) + ` ${currencyCode}`
        };
    };

    static calculateAdditionalCharge = (trx, additionalCharges, currencyCode) => {
        let totalAdditionalCharge = 0;
        const trxDetails = trx.details;

        if (!empty(trxDetails) && trx.trx_type === TRX_TYPE_NORMAL) {
            trxDetails.map(detail => {
                let itemPrice = PosPriceCalculator.calculateItemPrice(
                    trx,
                    detail,
                    currencyCode
                ).totalPriceValue;
                itemPrice =
                    itemPrice -
                    PosPriceCalculator.calculateGlobalDiscount(trx, itemPrice, currencyCode)
                        .totalDiscountValue;

                if (!empty(detail.pos_sales_type)) {
                    if (
                        !empty(
                            detail.pos_sales_type
                                .pos_sales_type_additional_charges
                        )
                    ) {
                        detail.pos_sales_type.pos_sales_type_additional_charges.map(
                            additionalCharge => {
                                const appliedAdditionalCharge = additionalCharges.find(
                                    row =>
                                        row.pos_additionalCharge_id ===
                                        additionalCharge.pos_additionalCharge_id
                                );
                                if (!empty(appliedAdditionalCharge)) {
                                    totalAdditionalCharge +=
                                        itemPrice *
                                        (parseFloat(
                                            appliedAdditionalCharge.charge_percent
                                        ) /
                                            100);
                                }

                                return additionalCharge;
                            }
                        );
                    }
                }

                return detail;
            });
        }

        return {
            additionalChargeValue: totalAdditionalCharge,
            formattedAdditionalCharge:
                number_format(totalAdditionalCharge, 0) + ` ${currencyCode}`
        };
    };

    static calculateTax = (subTotal, serviceCharge, additionalCharge, tax, currencyCode) => {
        let totalTax = 0;
        let taxPercent = 0;

        if (!empty(tax)) {
            if(!empty(tax.tax_percent) && tax.is_included_in_price !== 1){
                taxPercent = tax.tax_percent;
                totalTax =
                    (subTotal + serviceCharge + additionalCharge) *
                    (parseFloat(tax.tax_percent) / 100);
            }
        }

        return {
            taxValue: totalTax,
            taxPercent: number_format(taxPercent, 0),
            formattedTax: number_format(totalTax, 0) + ` ${currencyCode}`
        };
    };

    static calculateGrandTotal = (trx, posConfig, currencyCode) => {
        let grandTotal = 0;
        if (!empty(trx.details)) {
            const subTotal = PosPriceCalculator.calculateSubTotal(trx, currencyCode).subTotal;
            const globalDiscount = PosPriceCalculator.calculateGlobalDiscount(
                trx, '', currencyCode
            ).totalDiscountValue;
            const serviceCharge = PosPriceCalculator.calculateServiceCharge(
                trx,
                posConfig.service_charge,
                currencyCode
            ).serviceChargeValue;
            const additionalCharge = PosPriceCalculator.calculateAdditionalCharge(
                trx,
                posConfig.additional_charge,
                currencyCode
            ).additionalChargeValue;
            let tax = 0;
            if(trx.trx_type === TRX_TYPE_NORMAL || trx.trx_type === TRX_TYPE_COUPON_SALES){
                tax = PosPriceCalculator.calculateTax(
                    subTotal - globalDiscount,
                    serviceCharge,
                    additionalCharge,
                    posConfig.tax,
                    currencyCode
                ).taxValue;
            }
            grandTotal =
                subTotal -
                globalDiscount +
                serviceCharge +
                additionalCharge +
                tax;
        }

        return {
            grandTotal,
            formattedGrandTotal: number_format(grandTotal, 0) + ` ${currencyCode}`
        };
    };

    static calculateTotalQty = trx => {
        let totalQty = 0;
        if (!empty(trx.details)) {
            trx.details.map(detail => {
                totalQty += detail.qty;
                return detail;
            });
        }

        return totalQty;
    };

    static generateTrxDrvValues = (currentTrx, posConfig, currencyCode) => {
        const grossTotal = PosPriceCalculator.calculateGrossTotal(
            currentTrx.details,
            null,
            currencyCode
        );
        const subTotal = PosPriceCalculator.calculateSubTotal(currentTrx, currencyCode);
        const globalDiscount = PosPriceCalculator.calculateGlobalDiscount(
            currentTrx, '', currencyCode
        );
        const subTotalAfterGlobalDiscount =
            subTotal.subTotal - globalDiscount.totalDiscountValue;
        const serviceCharge = PosPriceCalculator.calculateServiceCharge(
            currentTrx,
            posConfig.service_charge,
            currencyCode
        );
        const additionalCharge = PosPriceCalculator.calculateAdditionalCharge(
            currentTrx,
            posConfig.additional_charge,
            currencyCode
        );
        let tax = 0;
        if(currentTrx.trx_type === TRX_TYPE_NORMAL || currentTrx.trx_type === TRX_TYPE_COUPON_SALES){
            tax = PosPriceCalculator.calculateTax(
                subTotalAfterGlobalDiscount,
                serviceCharge.serviceChargeValue,
                additionalCharge.additionalChargeValue,
                posConfig.tax,
                currencyCode
            );
        }
        const grandTotal = PosPriceCalculator.calculateGrandTotal(
            currentTrx,
            posConfig,
            currencyCode
        );

        const totalQty = PosPriceCalculator.calculateTotalQty(currentTrx);

        return {
            drv_gross_total: grossTotal.grossTotal,
            drv_total_discount_nominal: globalDiscount.totalDiscountValue,
            drv_subtotal: subTotal.subTotal,
            drv_total_service_charge: serviceCharge.serviceChargeValue,
            drv_total_additional_charge: additionalCharge.additionalChargeValue,
            drv_tax: tax.taxValue,
            drv_grand_total: grandTotal.grandTotal,
            drv_qty: totalQty
        };
    };
}
