import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import "moment/locale/id";
import { Provider } from "react-redux";

import "antd/dist/antd.min.css";
import "./shared/styles/initial.module.scss";
import 'react-overlay-loader/styles.css';

import Routes from "./routes";
import configureStore from "./state/store";
// import registerServiceWorker from "./registerServiceWorker";
import { loadState, saveState } from "./shared/helpers/stateHelper";
import axios from "axios";
import { empty } from "./shared/helpers/generalHelper";
import * as Sentry from "@sentry/browser";
import Synchronizer from "./shared/classes/Synchronizer";
import { authOperations } from "./state/ducks/auth";
import { APP_VERSION, BUILD_NUMBER } from "./shared/utils/constants";
import { initMessageListener } from "redux-state-sync";

Sentry.init({dsn: "https://a5d1cf60335f4c4ba4582ed6fe52cea9@sentry.io/1535728"});

moment.locale('id');
const initial_data = loadState(); //window.REDUX_INITIAL_DATA
const reduxStore = configureStore(initial_data);

initMessageListener(reduxStore);

reduxStore.subscribe(() => {
    saveState(reduxStore.getState());
});

const client_key = localStorage.getItem('client_key');
const login_token = localStorage.getItem('login_token');
if (!empty(client_key)) {
    axios.defaults.headers.common['Authorization'] = client_key;
    axios.defaults.headers.common['APP-Token'] = login_token;
    axios.defaults.headers.common['Authorization-Type'] = 'business_user';
    axios.defaults.headers.common['Platform'] = 'web';
    axios.defaults.headers.common['App-Version'] = APP_VERSION;
    axios.defaults.headers.common['App-Build'] = BUILD_NUMBER;
}

reduxStore.dispatch(authOperations.resetAppStates());

ReactDOM.render(
    <Provider store={reduxStore}>
        <Synchronizer autoStart={true} />
        <Routes />
    </Provider>,
    document.getElementById('root')
);
// registerServiceWorker();
