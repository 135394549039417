import React, {Component} from "react";
import {Button, Col, Empty, message, Row, Spin, Modal} from "antd";
import "./PosReceiptPublic.module.scss";
import {empty} from "../shared/helpers/generalHelper";
import {apiCall} from "../shared/helpers/networkHelper";
import * as endpoint from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import PosPriceListPublic from "../modules/PosPriceListPublic/PosPriceListPublic";

class PosReceiptPublic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trx: null,
            retrieved: false,
            loading: false,
            rate_loaded: false,
            rate_obj: null,
            is_rated: false,
            rating: 0,
            review: '',
            google_review_url: ''
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        apiCall(
            endpoint.POST_POS_RECEIPT_RETRIEVE + `/${this.props.match.params.receipt_token}`,
            "get",
            null,
            result => {
                this.setState({
                    trx: result.data
                });

                if(!empty(result.data?.customer)){
                    apiCall(
                        'v3/pos-trx/rate?trx_id=' + result.data.local_trx_id,
                        "get",
                        null,
                        res => {
                            if(empty(res.data.rating_data)){
                                this.setState({
                                    rate_obj: null,
                                    rate_loaded: true,
                                    is_rated: false,
                                    google_review_url: res.data.google_review_url,
                                })
                            }else{
                                this.setState({
                                    rate_obj: res.data.rating_data,
                                    google_review_url: res.data.google_review_url,
                                    rate_loaded: true,
                                    is_rated: true
                                })
                            }
                        },
                        error => {
                            ErrorHandler.handleGeneralError(error);
                        });
                }
            },
            error => {
                ErrorHandler.handleGeneralError(error);
            },
            () => {
                this.setState({
                    loading: false,
                    retrieved: true
                });
            }
        );
    }

    handleRatingChange = (e) =>{
        this.setState({
            rating: e.target.value
        });
    };

    handleRateSubmit = () => {
        if(empty(this.state.rating)){
            message.error('Rating harus diisi.');
            return;
        }

        this.setState({
            loading: true
        });

        const form_data = {
            trx_id: this.state.trx?.local_trx_id,
            rating: this.state.rating,
            comment: this.state.review
        };

        apiCall(
            'v3/pos-trx/rate',
            "post",
            form_data,
            result => {
                message.success('Ulasan anda telah berhasil dikirim!');
                this.setState({
                    is_rated: true,
                    rate_loaded: true,
                    rate_obj: form_data
                });

                if(!empty(this.state.google_review_url) && this.state.rating > 3){
                    const { confirm } = Modal;
                    const google_review_url = this.state.google_review_url;

                    confirm({
                        title: 'Terima Kasih',
                        content: 'Apakah Anda bersedia untuk memberikan review dan rating baik pada halaman Google kami?',
                        onOk() {
                            window.open(google_review_url, '_blank').focus();
                        },
                        onCancel() {},
                        okText: 'Ya, Bersedia',
                        cancelText: 'Tidak, Terima Kasih'
                    });
                }
            },
            error => {
                ErrorHandler.handleGeneralError(error);
            },
            () => {
                this.setState({
                    loading: false
                });
            });
    };

    render() {
        const {trx, retrieved, loading} = this.state;

        return (
            <Spin spinning={loading} tip="loading..">
                <Row>
                    <Col span={24}>
                        {!empty(trx) && (
                            <>
                                <PosPriceListPublic
                                    trx={trx}
                                    posConfig={trx.cashier_configs}
                                    isPrint={true}
                                    isLegit={true}
                                    preSettlement={false}
                                    isPublic={true}
                                />


                                <div style={{
                                    width: '500px',
                                    maxWidth: '100%',
                                    margin: '50px auto 100px auto',
                                    textAlign: 'center'
                                }}>
                                    {this.state.rate_loaded === true && empty(this.state.rate_obj) && !this.state.is_rated && (
                                        <>
                                            <h4>Berikan Rating dan Ulasan</h4>

                                            <div id="star-rating" style={{
                                                marginTop: '20px'
                                            }}>
                                                <div className="rating-group">
                                                    <input key={Math.random()} disabled checked className="rating__input rating__input--none"
                                                           name="rating3" id="rating3-none" value="0" type="radio"/>
                                                    <label aria-label="1 star" className="rating__label"
                                                           htmlFor="rating3-1"><i
                                                        className="rating__icon rating__icon--star fa fa-star"></i></label>
                                                    <input key={Math.random()} className="rating__input" name="rating3" id="rating3-1" value="1" checked={this.state.rating === '1'}
                                                           type="radio" onChange={(e) => { this.handleRatingChange(e) }} />
                                                    <label aria-label="2 stars" className="rating__label"
                                                           htmlFor="rating3-2"><i
                                                        className="rating__icon rating__icon--star fa fa-star"></i></label>
                                                    <input key={Math.random()} className="rating__input" name="rating3" id="rating3-2" value="2" checked={this.state.rating === '2'}
                                                           type="radio" onChange={(e) => { this.handleRatingChange(e) }} />
                                                    <label aria-label="3 stars" className="rating__label"
                                                           htmlFor="rating3-3"><i
                                                        className="rating__icon rating__icon--star fa fa-star"></i></label>
                                                    <input key={Math.random()} className="rating__input" name="rating3" id="rating3-3" value="3" checked={this.state.rating === '3'}
                                                           type="radio" onChange={(e) => { this.handleRatingChange(e) }} />
                                                    <label aria-label="4 stars" className="rating__label"
                                                           htmlFor="rating3-4"><i
                                                        className="rating__icon rating__icon--star fa fa-star"></i></label>
                                                    <input key={Math.random()} className="rating__input" name="rating3" id="rating3-4" value="4" checked={this.state.rating === '4'}
                                                           type="radio" onChange={(e) => { this.handleRatingChange(e) }} />
                                                    <label aria-label="5 stars" className="rating__label"
                                                           htmlFor="rating3-5"><i
                                                        className="rating__icon rating__icon--star fa fa-star"></i></label>
                                                    <input key={Math.random()} className="rating__input" name="rating3" id="rating3-5" value="5" checked={this.state.rating === '5'}
                                                           type="radio" onChange={(e) => { this.handleRatingChange(e) }} />
                                                </div>
                                            </div>

                                            <div style={{
                                                marginTop: '15px',
                                                width: '100%'
                                            }}>
                                        <textarea style={{
                                            width: '300px',
                                            maxWidth: '100%',
                                            borderRadius: '8px',
                                            padding: '5px 10px',
                                            borderColor: '#AAA',
                                            textAlign: 'center'
                                        }} value={this.state.review} onChange={e => { this.setState({review: e.target.value}) }} placeholder={`Tulis ulasanmu di sini..`} rows={5} />
                                            </div>

                                            <div>Powered by <a href="https://gdbusiness.id" rel="noopener noreferrer" target="_blank">GD Business</a></div>

                                            <div style={{
                                                marginTop: '20px'
                                            }}>
                                                <Button onClick={this.handleRateSubmit} type={"primary"}>Kirim Ulasan</Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.rate_loaded && !empty(this.state.rate_obj) && this.state.is_rated && (
                                        <>
                                            <h4>Rating & Ulasan</h4>
                                            <div>Rating: {empty(this.state.rate_obj) ? this.state.rating : this.state.rate_obj?.rating}</div>
                                            <div>Ulasan: {(empty(this.state.rate_obj) ? this.state.review : this.state.rate_obj?.comment) || '-'}</div>
                                            <br/>
                                            <div>Powered by <a href="https://gdbusiness.id" rel="noopener noreferrer" target="_blank">GD Business</a></div>
                                            {/* {(this.state.rating > 3 || this.state.rate_obj?.rating > 3) && !empty(this.state.google_review_url) && (
                                                <div style={{marginTop: 20}}>
                                                    <h4>Rate us on Google</h4>
                                                    <a href={this.state.google_review_url} target="_blank" rel="noopener noreferrer">{this.state.google_review_url}</a>
                                                </div>
                                            )} */}
                                        </>
                                    )}

                                </div>
                            </>
                        )}
                        {empty(trx) && !retrieved && (
                            <Empty style={{marginTop: '15vh'}} description="Loading receipt, please wait.."/>
                        )}
                        {empty(trx) && retrieved && (
                            <Empty style={{marginTop: '15vh'}} description="Failed to load receipt, please try again."/>
                        )}
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export default PosReceiptPublic;
