import * as types from './types';
import uuid from 'uuid/v4';
import { now } from '../../../shared/helpers/dateHelper';
import { empty } from "../../../shared/helpers/generalHelper";

export const storeItems = items => {
    return dispatch => {
        dispatch({
            type: types.STORE_ITEMS,
            payload: { items }
        });
    };
};

export const storeItemCategories = itemCategories => {
    return dispatch => {
        dispatch({
            type: types.STORE_ITEM_CATEGORIES,
            payload: { itemCategories }
        });
    };
};

export const storeDiscounts = discounts => {
    return dispatch => {
        dispatch({
            type: types.STORE_DISCOUNTS,
            payload: { discounts }
        });
    };
};

export const storeCommissionWorkers = commission_workers => {
    return dispatch => {
        dispatch({
            type: types.STORE_COMMISSION_WORKERS,
            payload: { commission_workers }
        });
    };
};

export const populateAppointments = (appointments = []) => {
    return dispatch => {
        dispatch({
            type: types.POPULATE_APPOINTMENTS,
            payload: appointments
        });
    };
};

export const setNewAppointment = (appointmentData = {}) => {
    return dispatch => {
        dispatch({
            type: types.SET_NEW_APPOINTMENT,
            payload: appointmentData
        });
    };
};

export const setAppointmentProps = (local_appointment_id, newProps) => {
    return dispatch => {
        dispatch({
            type: types.EDIT_APPOINTMENT,
            payload: {
                local_appointment_id,
                newProps
            }
        });
    };
};

export const deleteAppointment = local_appointment_id => {
    return dispatch => {
        dispatch({
            type: types.DELETE_APPOINTMENT,
            payload: {
                local_appointment_id
            }
        });
    };
};

export const setNewLocalTrx = (additionalProps = []) => {
    return dispatch => {
        dispatch({
            type: types.SET_NEW_LOCAL_TRX,
            payload: {
                local_trx_id: uuid(),
                trx_local_start_time: now(),
                document_date: now(),
                ...additionalProps
            }
        });
    };
};

export const addTrx = (trx) => {
    return dispatch => {
        dispatch({
            type: types.ADD_TRX,
            payload: {
                ...trx
            }
        });
    };
};

export const switchCurrentTrx = local_trx_id => {
    return dispatch => {
        dispatch({
            type: types.SWITCH_CURRENT_TRX,
            payload: {
                local_trx_id
            }
        });
    };
};

export const addTrxGlobalDisc = (currentLocalTrxId, discountItem) => {
    return dispatch => {
        dispatch({
            type: types.ADD_TRX_GLOBAL_DISC,
            payload: {
                local_trx_id: currentLocalTrxId,
                discountItem
            }
        });
    };
};

export const addTrxDetail = (
    currentLocalTrxId,
    currentTrxDetail,
    itemMeta,
    itemData,
    cb = ()=>{}
) => {
    let isNew = true;
    if(!empty(currentTrxDetail)){
        currentTrxDetail.map(detail => {
            if (
                detail.pos_item_id === itemMeta.pos_item_id &&
                detail.pos_item_variant_id === itemData.pos_item_variant_id &&
                itemData.is_package === 0
            ) {
                isNew = false;
            }

            return detail;
        });
    }

    if (isNew) {
        return dispatch => {
            dispatch({
                type: types.ADD_NEW_TRX_DETAIL,
                payload: {
                    local_trx_id: currentLocalTrxId,
                    local_trx_detail_id: uuid(),
                    itemMeta,
                    itemData
                }
            });
        };
    } else {
        return dispatch => {
            dispatch({
                type: types.ADD_EXISTING_TRX_DETAIL,
                payload: {
                    local_trx_id: currentLocalTrxId,
                    local_trx_detail_id: currentTrxDetail.local_trx_detail_id,
                    itemMeta,
                    itemData
                }
            });
        };
    }
};

export const editTrxDetail = (local_trx_id, local_trx_detail_id, detail) => {
    return dispatch => {
        dispatch({
            type: types.EDIT_TRX_DETAIL,
            payload: {
                local_trx_id,
                local_trx_detail_id,
                detail
            }
        });
    };
};

export const deleteTrxDetail = (local_trx_id, local_trx_detail_id, deletedDetail=null, actionType = "DELETE_ITEM") => {
    return dispatch => {
        dispatch({
            type: types.DELETE_TRX_DETAIL,
            payload: {
                local_trx_id: local_trx_id,
                local_trx_detail_id: local_trx_detail_id,
                deletedDetail,
                actionType
            }
        });
    };
};

export const logModifyTrxDetail = (local_trx_id, local_trx_detail_id, deletedDetail=null, actionType = "DELETE") => {
    return dispatch => {
        dispatch({
            type: types.MODIFY_QTY_LOG,
            payload: {
                local_trx_id: local_trx_id,
                local_trx_detail_id: local_trx_detail_id,
                deletedDetail,
                actionType
            }
        });
    };
};

export const markTrxDetailsAsPrinted = (local_trx_id) => {
    return dispatch => {
        dispatch({
            type: types.MARK_TRX_DETAILS_AS_PRINTED,
            payload: {
                local_trx_id
            }
        });
    };
};

export const setTrxCustomer = (localTrxId, customerData) => {
    return dispatch => {
        dispatch({
            type: types.SET_TRX_CUSTOMER,
            payload: {
                local_trx_id: localTrxId,
                customerData
            }
        });
    };
};

export const setTrxProps = (local_trx_id, newProps) => {
    return dispatch => {
        dispatch({
            type: types.SET_TRX_PROPS,
            payload: {
                local_trx_id,
                newProps
            }
        });
    };
};

export const setTrxStates = (local_trx_id, newStates) => {
    return dispatch => {
        dispatch({
            type: types.SET_TRX_STATES,
            payload: {
                local_trx_id,
                newStates
            }
        });
    };
};

export const resetTrx = local_trx_id => {
    return dispatch => {
        dispatch({
            type: types.RESET_TRX,
            payload: {
                local_trx_id
            }
        });
    };
};

export const deleteTrx = (local_trx_id, trx = null) => {
    return dispatch => {
        if(!empty(trx)){
            dispatch({
                type: types.DELETE_TRX_LOG,
                payload: {
                    trx
                }
            });
        }

        dispatch({
            type: types.DELETE_TRX,
            payload: {
                local_trx_id
            }
        });
    };
};

export const overwriteTrxs = trxs => {
    return dispatch => {
        dispatch({
            type: types.OVERWRITE_TRXS,
            payload: {
                trxs
            }
        });
    };
};

export const setPosConfig = posConfig => {
    return dispatch => {
        dispatch({
            type: types.SET_POS_CONFIG,
            payload: posConfig
        });
    };
};

export const setPosVariables = posVariables => {
    return dispatch => {
        dispatch({
            type: types.SET_POS_VARIABLES,
            payload: posVariables
        });
    };
};

export default {
    storeItems,
    storeItemCategories,
    storeDiscounts,
    storeCommissionWorkers,
    addTrxGlobalDisc,
    populateAppointments,
    setNewAppointment,
    setAppointmentProps,
    deleteAppointment,
    setNewLocalTrx,
    addTrx,
    addTrxDetail,
    editTrxDetail,
    deleteTrxDetail,
    logModifyTrxDetail,
    markTrxDetailsAsPrinted,
    setTrxCustomer,
    setTrxProps,
    setTrxStates,
    switchCurrentTrx,
    resetTrx,
    deleteTrx,
    overwriteTrxs,
    setPosConfig,
    setPosVariables
};
