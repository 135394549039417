import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import * as reducers from "./ducks"; // import all reducers from ducks/index.js
import { createStateSyncMiddleware } from "redux-state-sync";


const config = {
    // TOGGLE_TODO will not be triggered in other tabs
    blacklist: ['TOGGLE_TODO'],
};

const middlewares = [thunk, createStateSyncMiddleware(config)]

export default function configureStore(initialState = {}) {
    const appReducer = combineReducers({...reducers});

    const rootReducer = (state, action) => {
        if (action.type === 'USER_SIGNOUT') {
            state = undefined;
        }

        return appReducer(state, action);
    }

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
}